<template src="./SkillGame.html"></template>

<script>
import VueGl from "../../components/VueGl/VueGl";

export default {
  name: "skillGame",
  components: {
    VueGl,
  },
  data() {
    return {
      role: "",
      userId: "",
      dataUser: "",
      nameGame: "",
    };
  },
  destroyed() {
      location.reload();
  },
  mounted() {
    this.role = this.$store.state.User.user.role.name;
    this.userId = this.$store.state.User.user.id;
    this.nameGame = this.$route.params.gameName;
  },
  methods: {
    sendText() {
      const infoUser = {
        token: this.$store.state.User.jwt,
        gameId: this.nameGame,
        userId: this.userId,
      };
      this.dataUser = JSON.stringify(infoUser);
      this.$refs.vuegl.message("Bridge", "SendToUnity", this.dataUser);
    },
    fullscreen() {
      this.$refs.vuegl.fullscreen();
    },
  },
};
</script>

<style lang="scss" scoped src="./SkillGame.scss"></style>